$hamburger-class: '.hamburger-custom';

@import '../../../../local/templates/megamart_default/resources/sass/system';

@import '../../../../local/templates/megamart_default/resources/sass/snippets/svg-icon-background';
@import '../../../../local/templates/megamart_default/resources/sass/layout/compact_menu';
@import '../../../../local/templates/megamart_default/resources/sass/layout/compact_header';
@import '../../../../local/templates/megamart_default/resources/sass/components/hamburger';
@import '../../../../local/templates/megamart_default/resources/sass/blocks/compact/all';

.icon-svg {
	&--nav-arrow-left {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-nav-arrow-left-view);
		}
	}

	&--search {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-search-view);
		}
	}

	&--user {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-user-view);
		}
	}

	&--mail {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-mail-view);
		}
	}

	&--phone {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-phone-view);
		}
	}

	&--map-pin {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-map-pin-view);
		}
	}
}

.l-compact-header {

	.landing-edit-mode & {
		opacity: 1;
		position: static;
		transform: none;
	}
}

.compact-user-panel {
	padding-bottom: 0.88rem;
	padding-top: 0.88rem;
}

.l-page__compact {
	position: relative;
	z-index: 101;

	.landing-edit-mode & > div {
		height: auto !important;
	}
}

.l-compact-menu {
	z-index: 100;
}

.l-compact-header__block--personal {
	margin-right: 0;

	.b-header-user__icon {
		color: var(--primary);
	}
}
.l-compact-header__block--logo {
	margin-left: 0;
}

.compact-search {
	&__input {
		.bmd-form-control {
			width: 100%;

			@include media-breakpoint-down(md) {
				background: transparent !important;
				border: none !important;
				outline: none;
			}
		}
	}

	&__button {
		.icon-svg {
			color: var(--primary);
		}
	}

	&__close {
		.icon-svg {
			color: #bdbdbd;
		}
	}

	.bmd-form-group {
			.bmd-form-control {
				&:focus {
					border-color: var(--primary);
					box-shadow: none;

					@include media-breakpoint-up(md) {
						outline: none;
					}
				}
			}
		}
}